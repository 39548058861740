import { StoryblokComponent } from "@storyblok/react";
import { FC, useCallback } from "react";

import { getFieldValues } from "@./state";
import { ComponentFieldConditions } from "@forms/schema";

interface ISectionComponents {
  components: any[]; // eslint-disable-line
  flowId: string;
  useConditionalLogic?: boolean;
  conditions?: ComponentFieldConditions[];
  canContinue?: boolean;
}

export const SectionComponents: FC<ISectionComponents> = (
  { components, flowId, useConditionalLogic, conditions, canContinue },
) => {
  const showComponents = useCallback((): boolean => {
    if (useConditionalLogic && conditions && conditions.length) {
      return conditions.filter((x) => x.conditional_name && x.conditional_value).every((condition) => {
        const condStoreValues = getFieldValues(flowId, condition.conditional_name ?? "");
        return condStoreValues.some((condStoreValue) => (condStoreValue &&
          (condition.exclusionary
            ? condition.conditional_value === "ANY" ? !condStoreValue?.data?.submitValue : condStoreValue?.data?.submitValue?.toString().toLowerCase()
              !== condition.conditional_value?.toLowerCase()
            : condition.conditional_value === "ANY" ? !!condStoreValue?.data?.submitValue : condStoreValue?.data?.submitValue?.toString().toLowerCase()
              === condition.conditional_value?.toLowerCase())) ?? false);
      });
    }
    return true;
  }, [ conditions, flowId, useConditionalLogic ]);

  const areComponentsVisible = showComponents();
  return (
    <>
      <div data-component={ SectionComponents.name } className={ !areComponentsVisible ? "hidden" : "" }>
        { /* eslint-disable-next-line */ }
        { components?.map((nestedBlok: any) => (
          <StoryblokComponent
            blok={ nestedBlok }
            key={ nestedBlok._uid }
            flowId={ flowId }
            canContinue={ canContinue }
          />
        )) }
      </div>
    </>
  );
};
